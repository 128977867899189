import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "reference-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reference-data",
        "aria-label": "reference data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference data`}</h1>
    <p>{`The Reference data section consists of the following sections:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#medicare-card-number-validation"
        }}>{`Medicare card number validation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#dva-veteran-file-number-validation"
        }}>{`DVA Veteran File Number validation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#common-api-response-codes"
        }}>{`Common API response codes`}</a></li>
    </ul>
    <h2 {...{
      "id": "medicare-card-number-validation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#medicare-card-number-validation",
        "aria-label": "medicare card number validation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare card number validation`}</h2>
    <p>{`Medipass provides a NPM or Yarn Medicare number validation package to ensure the Medicare card number matches the expected and properly formatted values. This is available at: `}<a parentName="p" {...{
        "href": "https://github.com/medipass/validate-medicare-number"
      }}>{`https://github.com/medipass/validate-medicare-number`}</a></p>
    <p>{`If you wish to write your own validation routine, the following algorithm is the Medicare Check Digit Routine:`}</p>
    <p>{`(Digit 1) +`}</p>
    <p>{`(Digit 2 `}{`*`}{` 3) +`}</p>
    <p>{`(Digit 3 `}{`*`}{` 7) +`}</p>
    <p>{`(Digit 4 `}{`*`}{` 9) +`}</p>
    <p>{`(Digit 5) +`}</p>
    <p>{`(Digit 6 `}{`*`}{` 3) +`}</p>
    <p>{`(Digit 7 `}{`*`}{` 7) +`}</p>
    <p>{`(Digit 8 `}{`*`}{` 9)`}</p>
    <p>{`Divide the total by 10`}</p>
    <p>{`The remainder is the Check Digit.`}</p>
    <p>{`The check digit becomes the 9th digit in the Medicare Card number.`}</p>
    <p>{`The 10th digit is the Medicare Card Issue Number (values 1-9)`}</p>
    <h2 {...{
      "id": "dva-veteran-file-number-validation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dva-veteran-file-number-validation",
        "aria-label": "dva veteran file number validation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DVA Veteran File Number validation`}</h2>
    <p>{`The Veteran file number occupies a minimum of 3 and up to 9 Characters in total.`}</p>
    <p>{`DVA File Number contains the following fields:`}</p>
    <ul>
      <li parentName="ul">{`State Identifier (1 uppercase alpha character)`}</li>
      <li parentName="ul">{`War Code (up to 3 uppercase alpha characters or 1 space)`}</li>
      <li parentName="ul">{`Numeric Field (up to 6 numeric characters)`}</li>
      <li parentName="ul">{`Dependency indicator optional, 1 uppercase alpha character)`}</li>
    </ul>
    <p>{`The first character is the state code (an alphabetic character) - N, V, Q, W, S or T for the appropriate state/territory. Australian Capital Territory is included in New South Wales (N) and Northern Territory with South Australia (S).`}</p>
    <p><img parentName="p" {...{
        "src": "/images/state-identifier.png",
        "alt": null
      }}></img></p>
    <p>{`The next seven characters are the file number, made up of a war code plus numeric digits (at least one numeric and no spaces allowed between war code and numerics), where:`}</p>
    <ul>
      <li parentName="ul">{`if War code is 1 alphabetic character (or space), up to 6 numeric characters (AN, ANN, ... ANNNNNN)`}</li>
      <li parentName="ul">{`if War code is 2 alphabetic characters, up to 5 numeric characters (AAN, AANN, ... AANNNNN)`}</li>
      <li parentName="ul">{`if War code is 3 alphabetic characters, up to 4 numeric characters (AAAN, AAANN, ... AAANNNN)`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/war-codes.png",
        "alt": null
      }}></img></p>
    <p>{`For dependents of veterans, the last character is always an alphabetic character. The alphabetic code is generated in the order by which the cards are issued. For example A, B, C, D etc.`}</p>
    <p>{`The format must be as follows:`}</p>
    <ul>
      <li parentName="ul">{`1st character must be alpha;`}</li>
      <li parentName="ul">{`2nd character may be alpha or space;`}</li>
      <li parentName="ul">{`3rd character may be alpha or numeric;`}</li>
      <li parentName="ul">{`4th character may be alpha or numeric;`}</li>
      <li parentName="ul">{`5th character may be alpha or numeric;`}</li>
      <li parentName="ul">{`6th character may be alpha or numeric;`}</li>
      <li parentName="ul">{`7th character may be alpha or numeric;`}</li>
      <li parentName="ul">{`8th character may be alpha or numeric;`}</li>
      <li parentName="ul">{`9th character (if populated) must be alpha.`}</li>
    </ul>
    <p>{`Examples:`}</p>
    <p>{`W 1, NX5, NX5A, SCGW1234, SCGW1234B`}</p>
    <h2 {...{
      "id": "common-api-response-codes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#common-api-response-codes",
        "aria-label": "common api response codes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Common API response codes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Status code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success! Check out the body for the response payload.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success! There is no body in this response.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bad request. Don`}{`'`}{`t resend the request until you fix it. The response body may contain more details which are documented in each API.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized to use this endpoint. Check for missing or invalid authorization header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden to use this endpoint. We know who you are but you`}{`'`}{`re not allowed to do whatever you attempted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found. The path you`}{`'`}{`re trying to reach doesn`}{`'`}{`t exist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`409`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conflicted state. The resource you have requested is stale, refresh the resource and try again.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`410`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gone. The API you have called no longer exists.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`415`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsupported media type. Your request is in a format not supported.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests. You`}{`'`}{`re making too many API calls within a short time frame.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error. Oops, something went wrong on our side, try again later.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not implemented. You accessed a feature that is not yet implemented.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bad gateway. One of our partners is unavailable, please try again later.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Service unavailable. We`}{`'`}{`re currently offline, please try again later.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      